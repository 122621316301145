@use 'common'

@font-face
	font-family: common.$brendonFont
	src: url("/fonts/BrandonGrotesque-Regular.woff2")
	font-style: normal
	font-weight: 400
	font-display: block

@font-face
	font-family: common.$brendonFont
	src: url("/fonts/BrandonGrotesque-Bold.woff2")
	font-style: normal
	font-weight: 700
	font-display: block

@font-face
	font-family: common.$brendonFont
	src: url("/fonts/BrandonGrotesque-Black.woff2")
	font-style: normal
	font-weight: 900
	font-display: block
