@use 'common'
@use '../../node_modules/hiding-header/dist/style'
@use '_fonts'

html
	overflow-y: scroll

html,
body
	color: common.$textColor
	padding: 0
	margin: 0
	font-family: common.$bodyFont

h1, h2, h3, h4, h5, h6
	font-family: common.$headingFont
	font-weight: 700

a
	color: inherit
	text-decoration: none

button
	cursor: pointer

*
	box-sizing: border-box

:root
	--page-horizontal-spacing: 20px

	--header-height: 64px

	--content-width-normal: 1280px
	--content-width-narrow: 820px
	--content-width-thin: 576px
	--content-width-wide: 1600px

	--border-radius: 10px
